import React from "react"
import { css } from "@emotion/core"

import SEO from "../../components/seo"
import Layout from "../../components/layout"
import CustomNavTitle from "../../components/CustomNavTitle"
import ImageLinkItem from "../../components/ImageLinkItem"

import { theme } from "../../styles/theme"

import listImage_cr from "../../images/listimages/listimage-cardiac-risk.svg"
import listImage_pr from "../../images/listimages/listimage-pulmonary-risk.svg"
import listImage_fb from "../../images/listimages/listimage-fluid-balance.svg"

const Taco = ({ location }) => {
  return (
    <Layout>
      <SEO title="TACO Risk Assessment" />

      <CustomNavTitle
        title="TACO Risk Assessment"
        destination="/"
        location={location}
      />

      <div className="mt-gap-2" css={theme.components.row}>
        <p className="mb-gap-2 max-w-content">
          Complete the risk assessment below, for each transfusion episode, to
          ascertain your patient's risk of transfusion associated circulatory
          overload (TACO). Click on each icon to determine the risk for your
          patient:
        </p>

        <ul css={styles.list}>
          <li>
            <ImageLinkItem
              destination="/taco/assessment"
              destinationState={{ whichRisk: "cardiac-risk" }}
              imageName={listImage_cr}
              title="Cardiac Risk"
              small
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/taco/assessment"
              destinationState={{ whichRisk: "pulmonary-risk" }}
              imageName={listImage_pr}
              title="Pulmonary Risk"
              small
            />
          </li>
          <li>
            <ImageLinkItem
              destination="/taco/assessment"
              destinationState={{ whichRisk: "fluid-balance" }}
              imageName={listImage_fb}
              title="Fluid Balance"
              small
            />
          </li>
        </ul>

        <p css={styles.disclaimer}>
          The information provided is intended for determining adult risk only.
        </p>
      </div>
    </Layout>
  )
}

const styles = {
  list: css({
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.gap,
    "&>*": {
      marginBottom: theme.gap,
      marginRight: theme.gap,
      "@media (max-width: 620px)": {
        width: "100%",
        marginRight: 0,
      },
    },
  }),
  disclaimer: css({
    fontSize: "0.8rem",
  }),
}

export default Taco
